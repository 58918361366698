import React from 'react';
import '../Contact.css';

const Contact = () => {
    return (
        <section id="contact" className="contact">
            <div className="contact-content">
                <h2>Contact Us</h2>
                <p>Email: <a href="mailto:contact@b.com">business@firstcomestheidea.com</a></p>
            </div>
        </section>
    );
};

export default Contact;
