import React from 'react';
import '../About.css';
import about from "../about.png";
import team from "../team.png";

const About = () => {
    return (
        <section id="about" className="about">
            <h2>About Us</h2>
            <p>
                The reality around us is changing before our eyes. A decade ago, it seemed that 3D films would dominate cinematography, but time has shown that they have become just a genre curiosity. On the other hand, in the mid-90s, the phone seemed to be a complement to the entire range of electronics used, and today it has replaced half of the devices we used 25 years ago. My Space and Geocities have almost disappeared from the market, while the niche meta browser written by Larry Page and Sergey Brin and Mark Zuckerberg's student website have taken over the Internet for decades. Today, we wonder about the role of AI, to what extent it will replace content creation specialists, and to what extent it will turn out to be just a complex compiler of someone else's content.
                However, regardless of the directions of technology development and which of its fields will turn out to be dominant and which will be niche, one thing is certain: we are facing at least two decades in which life without it will be impossible.
                Forms, habits, trends and directions will change, but we will still be looking for a hairdresser, buying a car, ordering insurance, going on vacation remotely. A taxi, a bottle of wine, a scented candle on the table and the movie we are watching will reach us thanks to remote, mobile and virtual tools.
                FCTI is a technology company whose goal is to create new solutions in niches that are waiting to be filled and to build markets that are just waiting to be created. We will not create games, CRM tools, e-commerce stores or new cryptocurrency exchanges. Our proprietary solutions will focus on B2B platforms for industries with a strong supply of services and a shortage of tools enabling development and communication. At the same time, we will focus on mobile applications targeted at seniors, artists, the tourism and photography industries. We will implement tools with significant income potential that also have significant social goals. IT is effective only if it correlates with the real needs of recipients or creates needs in areas that are able to expand the market.
                We realize that today, profitability in IT tools is often associated with applications famous for their low-flying entertainment, humiliating challenges and the development of platforms by creating conflicts and disputes between users. We want to show that this is not the only way and that by being guided by higher ambitions, you can build and implement products with interesting value for the market and high profitability for investors.
                 </p>
            <img src={team} alt="Hero Image" className="about_image" />
            <p>
                IT is not an industry that allows for quick monetization of investments. The industry also does not guarantee success for each of the implemented projects. However, thanks to a reliable risk assessment and an innovative approach to implementation, we are able to limit the risk by creating high-quality products with a huge development potential.
                On the one hand, IT investments are operating in a space where we have the opportunity to create the reality around us, on the other hand, we operate in one of the few segments where an effective project can allow for a return of several or even several thousand percent.
                Effective IT must be a synergy of several areas. An interesting idea, a well-implemented project and effective marketing must meet in one field. At the same time, an innovative and bold approach to the implemented product is key. It is not an art to challenge Play Station and Nitendo, like Microsoft putting the Xbox console on the table years ago along with a billion dollars to promote it. Real success is building products that are popular on a global scale for years, basing success on the concept and rationalizing the costs of its implementation.</p>
            <img src={about} alt="Hero Image" className="about_image" />
        </section>
    );
};

export default About;
