import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import About from './components/About';
import Footer from './components/Footer';
import './App.css';
import Contact from "./components/Contact";

function App() {
  return (
      <div className="App">
        <Header />
        <Hero />
        <Services />
        <About />
        <Contact />
        <Footer />

      </div>
  );
}

export default App;
