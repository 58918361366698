import React from 'react';
import '../Hero.css';
import logo from '../fcti_logo.png';

const Hero = () => {
    return (
        <section id="hero" className="hero">
            <div className="hero-content">
                <img src={logo} alt="Hero Image" className="logo-image" />
                <p>First Comes The Idea is a technology company involved in the production, development, promotion and monetization of proprietary mobile applications and internet platforms. We create socially responsible projects that meet the needs of a growing market. We look for niches, areas where demand is much greater than the available tools. We do not follow market trends - we look for opportunities to overtake them!</p>
            </div>
        </section>
    );
};

export default Hero;
