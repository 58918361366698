import React from 'react';
import '../Services.css';
import logo_nms from '../newmyspace_logo.jpg';
import logo_islandify from '../islandify_logo.jpg';
import logo_modelso from '../modelso_logo.jpg';
import logo_fitcalendar from '../fitcalendar_logo.jpg';
import logo_wearhub from '../wearhub_logo.jpg';
import logo_buyproject from '../buyproject_logo.jpg';
import logo_secondlife from '../secondlife_logo.jpg';
import logo_optimalization from '../optimalization_logo.jpg';

const Services = () => {
    return (
        <section id="services" className="services">
            <h2>Our Projects</h2>
            <div className="service-cards">
                <div className="service-card">
                    <h3>NMS</h3>
                    <p>Is there still room for global internet platforms that will reach billions of people? We have an
                        idea for an interesting path that will allow us to challenge the largest ones within 3 years
                        without competing with them today. New My Space!</p>
                    <img src={logo_nms} alt="Hero Image" className="services_logo"/>
                </div>
                <div className="service-card">
                    <h3>Islandify</h3>
                    <p>Tourism accounts for 7.6% of the world's GDP. That is why services such as AirBnB, Hotels,
                        Booking are today's tycoons. What if the gate is still not closed?</p>
                    <img src={logo_islandify} alt="Hero Image" className="services_logo"/>
                </div>
                <div className="service-card">
                    <h3>ModelSo</h3>
                    <p>Instagram has become a medium for the modeling and photography industry. However, it is difficult
                        to describe it as a professional tool. Professionals are served by modeling agencies that direct
                        their offer to a very narrow group. Is there anything in between?</p>
                    <img src={logo_modelso} alt="Hero Image" className="services_logo"/>
                </div>
                <div className="service-card">
                    <h3>Fit Calendar</h3>
                    <p>There is no country in the world where the industry related to healthy eating, training, and
                        fitness clubs would not grow every year. Let's support its operation!</p>
                    <img src={logo_fitcalendar} alt="Hero Image" className="services_logo"/>
                </div>
                <div className="service-card">
                    <h3>Wear Hub</h3>
                    <p>The clothing industry boasts annual global sales of 1.45 trillion Euro. At the same time, it is
                        one of the few that has recorded growth during the Covid-19 pandemic. We will create a tool to
                        support its development.</p>
                    <img src={logo_wearhub} alt="Hero Image" className="services_logo"/>
                </div>
                <div className="service-card">
                    <h3>Buy Project</h3>
                    <p>When talking about clothing designers, architects, graphic designers or composers, we usually
                        mean dedicated services. And what about projects that have ended up in the drawer?</p>
                    <img src={logo_buyproject} alt="Hero Image" className="services_logo"/>
                </div>
                <div className="service-card">
                    <h3>Second Life</h3>
                    <p>For the first time in history, there are more people over 65 than under 5 in the world. Many
                        developed countries, including Japan, Great Britain and most EU countries, have more retirees
                        than children. We have the answer to the needs of this group.</p>
                    <img src={logo_secondlife} alt="Hero Image" className="services_logo"/>
                </div>
                <div className="service-card">
                    <h3>Optimalization</h3>
                    <p>1156 is considered the beginning of banking in the world. Over the course of nine centuries, we
                        have created thousands of commercial banks, exchange offices, stock exchanges, cryptocurrencies,
                        tax optimization and international accounting, off shore countries, and fintechs.</p>
                    <img src={logo_optimalization} alt="Hero Image" className="services_logo"/>
                </div>
            </div>
        </section>
    );
};

export default Services;
