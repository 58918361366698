import React from 'react';
import '../Header.css';
import logo from "../fcti_logo_long.png";

const Header = () => {
    return (
        <header className="header">
            {/*<div className="logo">First Comes The Idea!</div>*/}
            <img src={logo} alt="Hero Image" className="logo-image-long" />
            <nav className="nav">
                <a href="#hero">Home</a>
                <a href="#services">Services</a>
                <a href="#contact">Contact</a>
                <a href="#about">About</a>
            </nav>
        </header>
    );
};

export default Header;
